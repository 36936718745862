import { useState, useCallback } from 'react';

function getStorageValue<T>(
  type: StorageType,
  key: string,
  defaultValue: T
): T {
  if (typeof window === 'undefined') {
    throw new Error('Browser window undefined');
  }

  const savedValue =
    type === 'session'
      ? sessionStorage.getItem(key)
      : localStorage.getItem(key);

  // defaultValue is omitted if a value already exists in storage
  return savedValue !== null ? JSON.parse(savedValue) : defaultValue;
}

type StorageType = 'local' | 'session';

export default function useBrowserStorage<T>(
  type: StorageType,
  key: string,
  defaultValue: T
): [T, (value: T) => void] {
  const setStorageValue = useCallback(
    (newValue: T) => {
      if (type === 'session') {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    },
    [key, type]
  );

  const [value, setValue] = useState<T>(() => {
    const savedValue = getStorageValue(type, key, defaultValue);
    setStorageValue(savedValue);
    return savedValue;
  });

  const changeValue = useCallback(
    (newValue: T) => {
      setValue(newValue);
      setStorageValue(newValue);
    },
    [setStorageValue]
  );

  return [value, changeValue];
}
