import usePreviousPath from 'hooks/usePreviousPath/usePreviousPath';
import useRoute from 'hooks/useRoute/useRoute';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import routesFactory from 'routes';

type RouteBack =
  | {
      path: string;
      params: {};
    }
  | undefined;

export default function usePathBack() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const storedPreviousPath = usePreviousPath();

  const currentPath = `${location.pathname}${location.search}${location.hash}`;

  const parentRoute = useMemo(() => {
    let routeBack: RouteBack;

    routesFactory(t).find((route) => {
      const match = matchPath(location.pathname, {
        path: route.path,
        exact: true,
      });
      const pathBack = route.pathBack;

      if (match && pathBack) {
        routeBack = { path: pathBack, params: match.params };
        return true;
      }

      return false;
    });

    return routeBack;
  }, [location.pathname, t]);

  const parentPath = useRoute(parentRoute?.path || '', parentRoute?.params);

  const handleClickBack = useCallback(() => {
    if (parentPath) {
      if (
        storedPreviousPath &&
        storedPreviousPath.startsWith(parentPath) &&
        currentPath !== storedPreviousPath // prevent navigating with back button to current path
      ) {
        history.push(storedPreviousPath);
      } else {
        history.push(parentPath);
      }
    }
  }, [currentPath, history, parentPath, storedPreviousPath]);

  return useMemo(
    () => ({
      pathBack: parentRoute ? parentPath : undefined,
      handleClickBack,
    }),
    [handleClickBack, parentPath, parentRoute]
  );
}
