import dayjs from 'dayjs';
import refreshJwtToken from 'utils/auth/refreshJwtToken';
import parseJwtData from 'utils/auth/parseJwtData';
import { ACCESS_TOKEN } from 'utils/constants/browserStorageKeys';
import { isDevelopment } from 'utils/environment';

export enum EventType {
  Removed = 'removed',
  Added = 'added',
}

export type SubscribeCallback = (type: EventType) => void;

const isDev = isDevelopment();

class AuthManager {
  private token: string | null;
  private subscribers: Set<SubscribeCallback>;

  constructor() {
    // Save token to session storage in dev environment to not call refresh-jwt every time hot reloading triggers
    this.token = isDev ? sessionStorage.getItem(ACCESS_TOKEN) : null;
    this.subscribers = new Set();
  }

  subscribe(callback: SubscribeCallback) {
    this.subscribers.add(callback);
  }

  unsubscribe(callback: SubscribeCallback) {
    this.subscribers.delete(callback);
  }

  setToken(newToken: string) {
    this.token = newToken;

    if (isDev) {
      sessionStorage.setItem(ACCESS_TOKEN, newToken);
    }

    this.subscribers.forEach((callback) => callback(EventType.Added));
  }

  getToken() {
    return this.token;
  }

  removeToken() {
    this.token = null;

    if (isDev) {
      sessionStorage.removeItem(ACCESS_TOKEN);
    }

    this.subscribers.forEach((callback) => callback(EventType.Removed));
  }

  async refresh() {
    try {
      const token = await refreshJwtToken();
      if (!token) {
        return;
      }

      this.setToken(token);
      return token;
    } catch (error) {
      throw error;
    }
  }

  isExpired() {
    if (!this.token) {
      return true;
    }

    const { exp } = parseJwtData(this.token);
    return dayjs.unix(exp).isBefore(dayjs.utc());
  }

  willExpireSoon() {
    if (!this.token) {
      return true;
    }

    const { exp } = parseJwtData(this.token);
    return dayjs.unix(exp).diff(dayjs.utc(), 'minute') < 2;
  }
}

const instance = new AuthManager();

export default instance;
