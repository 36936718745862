import useBrowserStorage from 'hooks/useBrowserStorage/useBrowserStorage';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PREVIOUS_PATH } from 'utils/constants/browserStorageKeys';

export default function usePreviousPath() {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useBrowserStorage<string | null>(
    'session',
    PREVIOUS_PATH,
    null
  );
  const previousPathRef = useRef<string | null>(previousPath);

  useEffect(() => {
    const currentPath = `${location.pathname}${location.search}${location.hash}`;

    if (previousPathRef.current !== currentPath) {
      setPreviousPath(previousPathRef.current);
      previousPathRef.current = currentPath;
    }
  }, [location, setPreviousPath]);

  return previousPath;
}
