import { useBrowserStorage } from 'hooks';
import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { Settings } from 'types/availability';
import { DEFAULT_AVAILABILITY_SETTINGS } from 'utils/constants/availability';
import { AVAILABILITY_SETTINGS } from 'utils/constants/browserStorageKeys';

type AvailabilitySettingsProviderProps = {
  children?: ReactNode;
};

type AvailabilitySettingsState = {
  settings: Settings;
  changeSettings: (settings: Settings) => void;
};

const AvailabilitySettingsContext = createContext<
  AvailabilitySettingsState | undefined
>(undefined);

export default function AvailabilitySettingsProvider({
  children,
}: AvailabilitySettingsProviderProps) {
  const [settings, setSettings] = useBrowserStorage<Settings>(
    'local',
    AVAILABILITY_SETTINGS,
    DEFAULT_AVAILABILITY_SETTINGS
  );

  // Fill non-existing settings in local storage with default values
  useEffect(() => {
    const existingSettingsKeys = Object.keys(settings);
    const defaultSettingsKeys = Object.keys(DEFAULT_AVAILABILITY_SETTINGS);

    defaultSettingsKeys.forEach((key) => {
      const settingName = key as keyof Settings;

      if (!existingSettingsKeys.includes(settingName)) {
        setSettings({
          ...settings,
          [settingName]: DEFAULT_AVAILABILITY_SETTINGS[settingName],
        });
      }
    });
  }, [setSettings, settings]);

  const value = useMemo(
    () => ({ settings, changeSettings: setSettings }),
    [settings, setSettings]
  );

  return (
    <AvailabilitySettingsContext.Provider value={value}>
      {children}
    </AvailabilitySettingsContext.Provider>
  );
}

export { AvailabilitySettingsContext, AvailabilitySettingsProvider };
