import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { useBrowserStorage } from 'hooks';
import { IconPlusIOS, IconShareIOS } from 'components/icons';
import styles from './PWAInstallPopupIOS.module.scss';
import { PWA_INSTALL_POPUP_IOS } from 'utils/constants/browserStorageKeys';

export type PWAInstallPopupIOSProps = {
  closeOnClick?: boolean;
  forceShow?: boolean;
};

const isInStandaloneMode = () =>
  // @ts-ignore
  'standalone' in window.navigator && window.navigator.standalone;

export default function PWAInstallPopupIOS({
  closeOnClick = true,
  forceShow = false,
}: PWAInstallPopupIOSProps) {
  const [isClosed, setClosed] = useBrowserStorage(
    'local',
    PWA_INSTALL_POPUP_IOS,
    false
  );

  const handleClick = () => {
    if (closeOnClick) setClosed(true);
  };

  if (!forceShow && (isClosed || isInStandaloneMode())) {
    return null;
  }

  return (
    <div
      className="fixed top-0 left-0 w-full h-full"
      onClick={handleClick}
      role="dialog"
    >
      <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-gray-300"></div>

      <div className="fixed bottom-6 left-0 w-full flex justify-center">
        <div className="relative w-full max-w-md rounded-md mx-3 bg-white">
          <div className="flex items-center gap-5 p-4">
            <IconPlusIOS
              width={30}
              className={classNames('shrink-0', styles.plusIcon)}
            />
            <p className="text-gray-900 text-base">
              <Trans>
                Install this app to your iPhone:
                <br /> Tap{' '}
                <IconShareIOS
                  className={classNames('align-sub', styles.shareIcon)}
                />
                , then add to homescreen
              </Trans>
            </p>
          </div>

          <div className="absolute bottom-[-14px] left-1/2 -translate-x-1/2">
            <svg
              width="89"
              height="7"
              viewBox="0 0 89 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M88.5 0H0C16.0674 0 25.2816 2.47894 31.1237 4.48645C35.0988 5.85236 38.4387 7 43.5 7C48.3077 7 51.5621 5.96448 55.5676 4.69C61.9763 2.65088 68.8077 0 88.5 0Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
